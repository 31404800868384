import { default as aboutNH6IZJVSVcMeta } from "/var/www/dente.nadiva.test/pages/about.vue?macro=true";
import { default as forgotdP62kCZd2MMeta } from "/var/www/dente.nadiva.test/pages/auth/forgot.vue?macro=true";
import { default as login8FmdfdXhk3Meta } from "/var/www/dente.nadiva.test/pages/auth/login.vue?macro=true";
import { default as verify_45emailseEIV8lIzYMeta } from "/var/www/dente.nadiva.test/pages/auth/verify-email.vue?macro=true";
import { default as verify_45failedTD9j4PiS9iMeta } from "/var/www/dente.nadiva.test/pages/auth/verify-failed.vue?macro=true";
import { default as verify_45reset_45successj5jVaMNC3OMeta } from "/var/www/dente.nadiva.test/pages/auth/verify-reset-success.vue?macro=true";
import { default as verify_45resetlPsS2ehEI9Meta } from "/var/www/dente.nadiva.test/pages/auth/verify-reset.vue?macro=true";
import { default as cart0HPB1b2fJJMeta } from "/var/www/dente.nadiva.test/pages/cart.vue?macro=true";
import { default as contact8nCJp06g6QMeta } from "/var/www/dente.nadiva.test/pages/contact.vue?macro=true";
import { default as accountTs37U1shG9Meta } from "/var/www/dente.nadiva.test/pages/dashboard/account.vue?macro=true";
import { default as indexRMeJxCOyfKMeta } from "/var/www/dente.nadiva.test/pages/index.vue?macro=true";
import { default as privacy_45policyYG0NyhrUF1Meta } from "/var/www/dente.nadiva.test/pages/privacy-policy.vue?macro=true";
import { default as return_45policy5hgKiigH4dMeta } from "/var/www/dente.nadiva.test/pages/return-policy.vue?macro=true";
import { default as terms1xOUOm6DTyMeta } from "/var/www/dente.nadiva.test/pages/terms.vue?macro=true";
import { default as _91_46_46_46orderID_93YCPaU8GI6fMeta } from "/var/www/dente.nadiva.test/pages/tracking/[...orderID].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/var/www/dente.nadiva.test/pages/about.vue")
  },
  {
    name: "auth-forgot",
    path: "/auth/forgot",
    meta: forgotdP62kCZd2MMeta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/auth/forgot.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login8FmdfdXhk3Meta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/auth/login.vue")
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify-email",
    meta: verify_45emailseEIV8lIzYMeta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-failed",
    path: "/auth/verify-failed",
    meta: verify_45failedTD9j4PiS9iMeta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/auth/verify-failed.vue")
  },
  {
    name: "auth-verify-reset-success",
    path: "/auth/verify-reset-success",
    meta: verify_45reset_45successj5jVaMNC3OMeta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/auth/verify-reset-success.vue")
  },
  {
    name: "auth-verify-reset",
    path: "/auth/verify-reset",
    meta: verify_45resetlPsS2ehEI9Meta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/auth/verify-reset.vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: cart0HPB1b2fJJMeta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/cart.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/var/www/dente.nadiva.test/pages/contact.vue")
  },
  {
    name: "dashboard-account",
    path: "/dashboard/account",
    meta: accountTs37U1shG9Meta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/dashboard/account.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexRMeJxCOyfKMeta || {},
    component: () => import("/var/www/dente.nadiva.test/pages/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/var/www/dente.nadiva.test/pages/privacy-policy.vue")
  },
  {
    name: "return-policy",
    path: "/return-policy",
    component: () => import("/var/www/dente.nadiva.test/pages/return-policy.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/var/www/dente.nadiva.test/pages/terms.vue")
  },
  {
    name: "tracking-orderID",
    path: "/tracking/:orderID(.*)*",
    component: () => import("/var/www/dente.nadiva.test/pages/tracking/[...orderID].vue")
  }
]