import payload_plugin_qLmFnukI99 from "/var/www/dente.nadiva.test/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/var/www/dente.nadiva.test/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/dente.nadiva.test/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/dente.nadiva.test/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/dente.nadiva.test/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/dente.nadiva.test/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/dente.nadiva.test/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/dente.nadiva.test/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/dente.nadiva.test/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/dente.nadiva.test/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/dente.nadiva.test/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xSLIPjreZL from "/var/www/dente.nadiva.test/node_modules/nuxt-meta-pixel/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "/var/www/dente.nadiva.test/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/var/www/dente.nadiva.test/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_PSbK9A48ZQ from "/var/www/dente.nadiva.test/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_eskviOYyLt from "/var/www/dente.nadiva.test/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_client_4F1kZh3YAB from "/var/www/dente.nadiva.test/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/var/www/dente.nadiva.test/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/var/www/dente.nadiva.test/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import error_handler_kEP5FliEXj from "/var/www/dente.nadiva.test/plugins/error-handler.ts";
import oastify_client_8ij4QQSGKo from "/var/www/dente.nadiva.test/plugins/oastify.client.ts";
import todays_sO8mOCg3JC from "/var/www/dente.nadiva.test/plugins/todays.ts";
import vue_start_rating_DR3xQYQCpq from "/var/www/dente.nadiva.test/plugins/vue-start-rating.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_xSLIPjreZL,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_PSbK9A48ZQ,
  plugin_eskviOYyLt,
  plugin_client_4F1kZh3YAB,
  plugin_WLsn00x1qh,
  plugin_client_OVoKJro5pc,
  error_handler_kEP5FliEXj,
  oastify_client_8ij4QQSGKo,
  todays_sO8mOCg3JC,
  vue_start_rating_DR3xQYQCpq
]